import React from "react";
import { graphql } from "gatsby";
import * as _ from "./config/fragments";

import cx from "classnames";
import theme from "../constants/theme";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { Wrapper } from "../components/core";
import {
  AboutSubHeader,
  StandOutSection,
  GridIconBulletsSection,
  CTASection,
} from "../components/blocks";

export default function DetailPage(props) {
  const siteMetaData = props.data.site.siteMetadata;
  const siteData = props.data.allSanitySiteConfig.edges[0].node;
  const sharedData = props.data.allSanitySharedSections.edges[0].node;
  const pageData = props.data.sanityDetailPage;

  const contentBlocks = pageData.contentBlocks ?? [];

  return (
    <Layout
      locale={props.pageContext.locale}
      siteMetaData={siteMetaData}
      mainNav={siteData.mainNav}
      alternateNav={siteData.alternateNav}
      contact={sharedData.contact}>
      <SEO title={pageData.title} />
      <AboutSubHeader
        title={pageData.header?.title?.localized}
        subTitle={pageData.header?.subTitle?.localized}
        description={pageData.header?.description?.localized}
        coverImageUrl={pageData.header?.coverImage?.asset?.url}
        videoLink={pageData.header?.videoLink}
        showVideo={pageData.header?.showVideo}
      />
      {contentBlocks.map(block => {
        switch (block._type) {
          case "textBlockSection":
            return (
              <StandOutSection
                title={block.title?.localized}
                subTitle={block.subTitle?.localized}
                descriptionRichText={block.descriptionRichText}
                coverImageUrl={block.mainImage?.asset?.url}
                sectionColor={block.sectionColor ?? theme.colorsTw.bg_bg1}
                isFlipped={block.isFlipped ?? false}
              />
            );
          case "bulletsSection":
            return (
              <GridIconBulletsSection
                title={block.title?.localized}
                subTitle={block.subTitle?.localized}
                descriptionRichText={block.descriptionRichText}
                coverImageUrl={block.mainImage?.asset?.url}
                bulletItems={block.bulletItems ?? []}
                sectionColor={block.sectionColor ?? theme.colorsTw.bg_bg1}
              />
            );
        }
      })}
      <CTASection
        title={sharedData.callToActionSection?.title?.localized}
        callToAction={sharedData.callToActionSection?.callToAction}
      />
    </Layout>
  );
}

export const query = graphql`
  query ($locale: String!, $id: String!) {
    # Site Meta Data
    site {
      ...SiteMetaData
    }
    # Site
    allSanitySiteConfig {
      edges {
        node {
          ...MainNav
          ...AlternateNav
        }
      }
    }
    # Shared
    allSanitySharedSections {
      edges {
        node {
          ...SharedSections
        }
      }
    }
    # Page
    sanityDetailPage(id: { eq: $id }) {
      title
      header {
        title {
          localized(locale: $locale)
        }
        subTitle {
          localized(locale: $locale)
        }
        description {
          localized(locale: $locale)
        }
        coverImage {
          ...Image
        }
        videoLink
        showVideo
      }
      contentBlocks {
        ... on SanityTextBlockSection {
          ...TextBlockSection
        }
        ... on SanityBulletsSection {
          ...BulletsSection
        }
      }
    }
  }
`;
